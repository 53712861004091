export const properties = {
    //productionUrl: "https://demoestate.xyz",
    productionUrl: "https://unestateinsieme.it",  
    version: "1.31"
};


/*
TO DO PER RIPRISTINARE LA SITUAZIONE FUNZIONANTE:
- Togliere if in dashboard.js in modo da far sparire il fine estate per ruoli diversi da AM, CSP e ADMIN
- sostituire il file MyAdmin.js con MyAdmin_OK.js
- Ricordarsi la modifica per differenziare user-ente da abbonamento musei
*/